import styled from "styled-components";

export const Wrapper = styled.div`
width:100%;
max-width: var(--superMediumWidth);
margin:auto;
margin-top:20px;
padding: var(--padding-left-right);
`;

export const CharacterNavigation = styled.div`
width:100%;
max-width: var(--superMediumWidth);
margin:auto;
border-top: 1px solid black;

.active {
    border-bottom:none;
}

`;

export const CharacterNavigationTab = styled.div`
width:33.3%;
max-width: var(--superMediumWidth);
padding-top:10px;
padding-bottom:10px;
display:inline-block;
border-bottom:1px solid black;
border-left:1px solid black;
border-right:1px solid black;
text-align:center;
`;

export const CharacterContent = styled.div`
width:100%;
max-width: var(--superMediumWidth);
margin:auto;
margin-top:40px;
min-height:100px;
`;

export const Char = styled.div`
width:100%;
max-width: var(--superMediumWidth);
margin:auto;
margin-top:10px;
min-height:100px;

`;