import styled from 'styled-components';


export const Wrapper = styled.div`
position:absolute;
z-index:100;
height:90vh;
width:90vw;
left:5vw;
top:5vh;
color:var(--color-5);
background: var(--color-bg-2);
border: 3px solid;
border-color: var(--color-border-1);
border-radius: 12px;
`