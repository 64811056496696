import React, { useState } from 'react';
import { Link } from 'react-router-dom';
//Mobile Check
import {isMobile} from 'react-device-detect';
// Config
import { USER_IMG_URL } from '../../config'
//Functions
import UserFunctions from '../UserFunctions';
//Components
import GetText from '../GetText';
import UserDefaultImage from '../../images/user/profile-img-default.png'
import Footer from '../Footer';

import {MobileWrapper, MobileContent, MobileLogoImg } from './Header.styles';

const Header = ( NavigationActive ) => {




    /* Language */

        /* Detect Browser language */
        function getBrowserLocales(options = {}) {
            const defaultOptions = {
            languageCodeOnly: false,
            };
            const opt = {
            ...defaultOptions,
            ...options,
            };
            const browserLocales =
            navigator.languages === undefined
                ? [navigator.language]
                : navigator.languages;
            if (!browserLocales) {
            return undefined;
            }
            return browserLocales.map(locale => {
            const trimmedLocale = locale.trim();
            return opt.languageCodeOnly
                ? trimmedLocale.split(/-|_/)[0]
                : trimmedLocale;
            });
        }
        /* Detect Browser language End  */
    
        /* Check if user got language cookie */
        if(UserFunctions.getUserLanguage() == null) {
            var userLang = getBrowserLocales({ languageCodeOnly: true }) //only give back the language not the country "languageCodeOnly: true"
            UserFunctions.setUserLanguage(userLang[0]); //Save the first found language "userLang[0]"
        }
        /* Check if user got language cookie end */
        
        window.document.documentElement.lang = UserFunctions.getUserLanguage(); //Header html lang
    /* Language End */

  

    const [showSideNavigation, setSideNavigation] = useState(false);

    if((isMobile) || (!isMobile)) {
        return (
        <>
            {
                <MobileWrapper id="header">
                    <MobileContent>
                        <span className='sideNavigationIconWrapper' onClick={event => setSideNavigation(true)}>
                            <i className="fa-solid fa-bars sideNavigationIcon"></i>
                        </span>
                        <div className={(showSideNavigation == true) ? `sideNavigation visible` : `sideNavigation hidden`} >
                            {
                                <span className='sideNavigationIconWrapper' onClick={event => setSideNavigation(false)}>
                                    <i className="fa-solid fa-bars sideNavigationIcon"></i>
                                </span>
                            }
                            <div className='sideNavigationContent'>
                                {
                                    UserFunctions.getSessionHash() ? (
                                        <Link to={`/user/${UserFunctions.getUserName()}`}>
                                            <div className='sideNavigationHeaderUser' >
                                                <img class='sideNavigationHeaderUserImage' src={(UserFunctions.getUserImage()) ? ( USER_IMG_URL + UserFunctions.getUserImage() ) : ( UserDefaultImage )} alt='UserImage'/>
                                                <span className='sideNavigationHeaderInfo'>
                                                    <div className='sideNavigationHeaderInfoUserName'>{UserFunctions.getUserName()}</div>
                                                    <div className='sideNavigationHeaderInfoUserBudget'>500 Diamonds</div>
                                                </span>
                                            </div>
                                        </Link>
                                    ):null
                                }
                                <Navigation NavigationActive={NavigationActive} />
                                <div className='sideNavigationFooter'>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                        <div className={(showSideNavigation == true) ? `sideNavigationBackground visible` : `sideNavigationBackground hidden`} onClick={event => setSideNavigation(false)} >
                        </div>
                    </MobileContent>
                </MobileWrapper>
            }
        </>
        );
    }
    else
    {
        return <></>
    }
};

const Navigation = ({ hashtagObject, NavigationActive }) => (
    

    UserFunctions.getSessionHash() ? (
        <>
                {
                    ((isMobile) || (!isMobile)) ?
                        <div className='sideNavigationLink'>
                           <Link to='/' className={`${(NavigationActive === 'home') ? 'navigationActive' : ''}`}><i className="fas fa-house"></i> <i><span><GetText textName={'navigationHome'} /></span></i></Link>
                           {/*<Link to='/battle' className={`${(NavigationActive === 'battle') ? 'navigationActive' : ''}`}><i className="fa-solid fa-gamepad"></i> <span><GetText textName={'navigationBattle'} /></span></Link>*/}
                           <Link to='/lobby' className={`${(NavigationActive === 'lobby') ? 'navigationActive' : ''}`}><i className="fa-solid fa-gamepad"></i> <span> <GetText textName={'navigationLobby'} /></span></Link>
                           <Link to='/character' className={`${(NavigationActive === 'character') ? 'navigationActive' : ''}`}><i className="fa-solid fa-user"></i> <span><GetText textName={'navigationCharacter'} /></span></Link>
                           <Link to='/inventory' className={`${(NavigationActive === 'inventory') ? 'navigationActive' : ''}`}><i className="fas fa-box"></i> <i><span><GetText textName={'navigationInventory'} /></span></i></Link>
                           <Link to='/basar' className={`${(NavigationActive === 'basar') ? 'navigationActive' : ''}`}><i className="fa-solid fa-shop"></i> <i><span><GetText textName={'basar'} /></span></i></Link>
                           <Link to='/settings' className={`${(NavigationActive === 'settings') ? 'navigationActive' : ''}`}><i className="fas fa-tools"></i> <span><GetText textName={'navigation_settings'} /></span></Link>
                           <Link to='/logout' className={`${(NavigationActive === 'logout') ? 'navigationActive' : ''}`}><i className="fas fa-sign-out-alt"></i> <span><GetText textName={'navigation_logout'} /></span></Link>
                        </div>
                    :
                        <></>
                }
        </>
    ) : (
        <>
                {
                    ((isMobile) || (!isMobile)) ?
                        <div className='topNavigationLink'>
                            <Link to='/login'>
                                <button className="signin-button hover"><span><GetText textName='navigation_signin' /></span></button>
                            </Link>
                        </div>
                    :
                        <></>
                }
        </>
    )
);
export default Header;