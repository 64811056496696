import styled from 'styled-components';

export const LobbyWrapper = styled.div`
  width: 100%;
  border: 3px solid;
  border-color: var(--color-border-1);
  border-radius: 12px;
  padding: 16px;
`;

export const SearchField = styled.input`
  width: 100%;
  padding: 8px 16px;
  border: 1px solid var(--color-border-1);
  border-radius: 4px;
  margin-bottom: 16px;
  font-size: 16px;
`;

export const LobbyList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const LobbyRow = styled.li`
  display: grid;
  grid-template-columns: 0.25fr 2fr 3fr 1fr 1fr 1fr;
  gap: 16px;
  padding: 8px 0;
  border-bottom: 1px solid var(--color-border-1);
  font-size: var(--fontSmall);
  color:var(--color-5);
  align-items: center; // Text vertikal zentrieren

  ${(props) =>
    props.header &&
    `
    font-size: var(--fontMed);
    text-transform: uppercase;
  `}

  .joinButton {
    background:none;
    padding:0px;
    margin:0;
    border:none;
    font-size: var(--fontSmall);
    color:var(--color-5);

    :hover {
      color:var(--color-1);
    }
  }

`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

export const CreateLobby = styled.div`
position:absolute;
z-index:100;
height:90vh;
width:90vw;
left:5vw;
top:5vh;
color:var(--color-5);
background: var(--color-bg-2);
border: 3px solid;
border-color: var(--color-border-1);
border-radius: 12px;
`