import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import API from '../API';
//Mobile Check
import {isMobile} from 'react-device-detect';
//Functions
import UserFunctions from './UserFunctions';
// Components
import Button from './Button';
import Input from './Input';
import GetText from './GetText';
import Header from './Header';
import Footer from './Footer';
import Wrapper from './Wrapper'
import Spinner from './Spinner';
//Functions
import User from './UserFunctions';
// Styles
import { Wrapper as AuthWrapper } from './Auth/Auth.styles';
// Hooks
import { useSystemFetch } from '../hooks/useSystemFetch';

const Register = () => {

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setResultMessage] = useState('');
    const [messageError, setResultMessageError] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const [timerId, setTimerId] = useState(null); // initialisiere eine neue state variable
    const [usernameCheck, setUsernameCheck] = useState(false);
    
    const handleInput = e => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
    
        if (name === 'username') {
            setUsername(value);
            //Check username length
            if(value.length < userNameMinLength || value.length > userNameMaxLength) {
              setUsernameError(true);
            } else {
              setUsernameError(false);
            }
          
            // clear the previous timer
            if (timerId) clearTimeout(timerId);
          
            //Check Username available
            const newTimerId = setTimeout(() => {
              setUsernameCheck(true);
            }, 1000);
          
            // save the new timer id
            setTimerId(newTimerId);
        }

        
        if(name === 'email') setEmail(value);

        //Check password length 
        if(name === 'password') {
            setPassword(value);
            if(value.length < passwordMinLength) {
                setPasswordError(true);
            } else {
                setPasswordError(false);
            }
        }
    };

    useEffect(() => {
        if(usernameCheck){
            setUsernameCheck(false);
            handleSubmit('checkUsername');
        }
    }, [usernameCheck]);

    const {
        state,
        loading : systemLoading,
    } = useSystemFetch();

    const passwordMinLength = state && state.passwordMinLength;
    const userNameMinLength = state && state.userNameMinLength;
    const userNameMaxLength = state && state.userNameMaxLength;

    const handleSubmit = async (action) => {
        try {
            setError(false);
            setLoading(true);

            const register = await API.register(action, username, password, email);

            var resultUserId = register.userId;
            var resultUserName = register.userName;
            var resultSessionHash = register.sessionHash;
            var resultMessage = register.resultMessage;
            var registered = register.registered;
            var error = register.error;

            if(resultMessage) {
                if(error === true) {
                    setResultMessageError(true);
                }
                else
                {
                    setResultMessageError(false);
                }
                setResultMessage(resultMessage);

            }
            
            if(registered == true) {
                if(resultUserId) {
                    //User.setUserId(resultUserId);
                }
                if(resultUserName) {
                    //User.setUserName(resultUserName);
                }
                if(resultSessionHash) {
                    //User.setSessionHash(resultSessionHash);
                }

                if((resultUserId) && (resultUserName) && (resultSessionHash)) {
                    /*
                    setTimeout(() => {
                        navigate('/login');
                    }, 5000);
                    */
                }
            }

            setLoading(false);
        } catch (error) {
            setError(true);
            setLoading(false);
        }
    };

    return (
        <>
            <Header userName={UserFunctions.getUserName()} />
            <Wrapper>
                {
                    systemLoading ?
                        <Spinner /> 
                    :
                        <AuthWrapper>
                            <h3><GetText textName='signup_title' /></h3>

                            {error && <div className="error"><GetText textName={'error'} textStyle={'default'} /></div>}
                            
                            {
                                (message && messageError === true) ? 
                                    <GetText key={message} textName={message} textStyle={'error'} />
                                :
                                    null
                            }
                            {
                                (message && messageError === false) ? 
                                    <GetText key={message} textName={message} textStyle={'success'} />
                                :
                                    null
                            }
                            {
                                (usernameError) ? 
                                    (username.length < userNameMinLength) ?
                                        <GetText textName='username_too_short' textStyle={'error'}/>
                                    :
                                        <GetText textName='username_too_long' textStyle={'error'}/>
                                : null
                            }
                            {
                                (passwordError) ? 
                                    <GetText textName='password_too_short' textStyle={'error'} />
                                :
                                    null
                            }

                            <label><GetText textName='email' /></label>
                            <Input type='text' value={email} name='email' onChange={handleInput} className={'marginBottom20'} />
                            
                            <label><GetText textName='username' /></label>
                            {usernameError && (username.length < userNameMinLength ? <span class="regInputMsg"><GetText textName='username_too_short' /></span> : <span class="regInputMsg"><GetText textName='username_too_long' /></span>)}
                            <Input type='text' value={username} name='username' onChange={handleInput} className={'marginBottom20'} />

                            <label><GetText textName='password' /></label>
                            {passwordError && <span class="regInputMsg"><GetText textName='password_too_short' /></span>}
                            <Input type='password' value={password} name='password' onChange={handleInput} className={'marginBottom20'} />

                            <div className="marginTop4 marginBottom20">
                                <span><Link to='/legal/terms'><GetText textName='signup_terms' /></Link><br/><Link to='/legal/privacy'><GetText textName='signup_privacy' /></Link></span>
                            </div>
                            {
                            (loading) ?
                                <Spinner />
                            :
                                <Button text={<GetText textName='signup_button' />} className={'marginTop4 marginBottom20'} callback={() => handleSubmit('register')} />
                            
                            }
                            <div><GetText textName='signup_signin_text' /> <Link to='/login' className="marginTop4 marginBottom20"><GetText textName='signin_button' /></Link></div>
                        </AuthWrapper>
                }
            </Wrapper>
        </>
    )
}

export default Register;