import React, {useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API from '../API';
import { isMobile } from 'react-device-detect';
import Spinner from './Spinner';
import Header from './Header';
import Button from './Button';
import Input from './Input';
import Wrapper from './Wrapper';
import { RoomWrapper, RoomList, RoomRow,
        ButtonGroup,
        Slots, Slot,
        CharactersList, Character,
     } from './Room/Room.style';
import UserFunctions from './UserFunctions';

//Hooks
import { useRoomFetch } from '../hooks/useRoomFetch';
import { useCharacterFetch } from '../hooks/useCharacterFetch';

const Room = () => {
  const navigate = useNavigate();
  const [isJoined, setIsJoined] = useState(false);
  const [isJoinedTeam, setIsJoinedTeam] = useState(false);
  const [showStartButton, setShowStartButton] = useState(false);

  const {
    roomHash
  } = useParams();

  /* Get Data */
  /* Room Data */
  const {
    roomData,
    setReload: setReloadRoomFetch,
  } = useRoomFetch(roomHash, UserFunctions.getSessionHash());

  const [roomDataResults, setRoomDataResults] = useState()

  if(roomData.results) {
    if(roomDataResults != roomData.results[0])
    setRoomDataResults(roomData.results[0])
  }
  /* Room Data End */

  /* Characters Data */
  const [charactersDataResults, setCharactersDataResults] = useState()

  const {
    state : charactersData
} = useCharacterFetch(UserFunctions.getSessionHash());

  if(charactersData.results) {
    if(charactersDataResults != charactersData.results)
    setCharactersDataResults(charactersData.results)
  }
  /* Characters Data End */
  /* Get Data End */

  const [roomName, setRoomName] = useState('')
  const [error, setError] = useState(false);

  const handleInput = e => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;

    if(name === 'roomName') setRoomName(value)
  }


  
  const leaveRoom = async () => {
      try {
        setError(false);

        const apiLeaveRoom = await API.leaveRoom(roomHash, UserFunctions.getSessionHash());

        var success = apiLeaveRoom.results[0].success;

        if(success === true) {
          //Success
          UserFunctions.deleteRoomHash();
          setIsJoined(false);
          setIsJoinedTeam(false);
          navigate('/lobby')
        }
        else
        {
          //Error

        }
    } catch (error) {
        setError(true);
    }
  }

  const joinSpectate = async () => {
    try {
      setError(false);

      const apiJoinSpectate= await API.joinSpectate(roomHash, UserFunctions.getSessionHash());

      var success = apiJoinSpectate.results[0].success;

      if(success === true) {
        //Success
        setIsJoined(true);
        setIsJoinedTeam(false);
        setReloadRoomFetch(prev => !prev); // Trigger a reload of the useRoomFetch hook
      }
      else
      {
        //Error
        setIsJoined(false);
        setIsJoinedTeam(false);
      }
  } catch (error) {
      setError(true);
  }
}

  const leaveSpectate = async () => {
    try {
      setError(false);

      const apiLeaveSpectate = await API.leaveSpectate(roomHash, UserFunctions.getSessionHash());

      var success = apiLeaveSpectate.results[0].success;

      if(success === true) {
        //Success
        setIsJoined(false);
        setReloadRoomFetch(prev => !prev); // Trigger a reload of the useRoomFetch hook
      }
      else
      {
        //Error
        setIsJoined(true);
      }
  } catch (error) {
      setError(true);
  }
}

const joinTeam = async (teamId, teamSlot) => {
  try {
    setError(false);

    const apiJoinTeam = await API.joinTeam(roomHash, UserFunctions.getSessionHash(), teamId, teamSlot);

    var success = apiJoinTeam.results[0].success;
    var slotFull = apiJoinTeam.results[0].slotFull;

    if(success === true) {
      //Success
      setIsJoined(true);
      setIsJoinedTeam(true);
      setReloadRoomFetch(prev => !prev); // Trigger a reload of the useRoomFetch hook
    }
    else
    {
      //Error
      if(slotFull === true) {
        setIsJoinedTeam(false);
      }
    }
  } catch (error) {
      setError(true);
  }
}

const leaveTeam = async (teamId) => {
  try {
    setError(false);

    const apiLeaveTeam = await API.leaveTeam(roomHash, UserFunctions.getSessionHash(), teamId);

    var success = apiLeaveTeam.results[0].success;

    if(success === true) {
      //Success
      setIsJoined(false);
      setIsJoinedTeam(false);
      setReloadRoomFetch(prev => !prev); // Trigger a reload of the useRoomFetch hook
    }
    else
    {
      //Error
      setIsJoined(true);
    }
  } catch (error) {
      setError(true);
  }
}

const chooseCharacter = async (characterId) => {
  try {
    setError(false);

    const apiChooseCharacter = await API.chooseCharacter(roomHash, UserFunctions.getSessionHash(), characterId);

    var success = apiChooseCharacter.results[0].success;

    if(success === true) {
      //Success
      setReloadRoomFetch(prev => !prev); // Trigger a reload of the useRoomFetch hook
    }
    else
    {
      //Error

    }
  } catch (error) {
      setError(true);
  }
}

const joinBattle = async (action) => {
  try {
    setError(false);

    const joinBattle = await API.joinBattle(roomHash, UserFunctions.getSessionHash());

    var gameStarted = joinBattle.results[0].gameStarted;

    if(gameStarted === true) {
      //Success
      setReloadRoomFetch(prev => !prev); // Trigger a reload of the useRoomFetch hook
    }
    else
    {
      //Error

    }
  } catch (error) {
      setError(true);
  }
}

  if(roomDataResults) {

    if(roomDataResults.roomState === 1) {
      navigate('/battle');
    }
    if(roomDataResults.roomLead === UserFunctions.getUserId()) {
      if(showStartButton !== true) {
        setShowStartButton(true);
      }
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
        setReloadRoomFetch(prev => !prev); // Trigger a reload of the useRoomFetch hook
    }, 5000); // Interval for 5 seconds

    // Cleanup function to be called when the component unmounts:
    return () => {
        clearInterval(intervalId);
    };
}, []);


  return (
    <>
      <Header userName={UserFunctions.getUserName()} />
      <Wrapper>
        <RoomWrapper>
          <RoomList>
            <RoomRow header>
              <span>Game</span>
              <span>Description</span>
              <span>Players</span>
              <span>Map</span>
              <span>Status</span>
            </RoomRow>
            <RoomRow>
              {
                (roomDataResults) ?
                  <>
                    <span>{roomDataResults.roomLead}</span>
                    <span>{roomDataResults.roomDescription}</span>
                    <span>{roomDataResults.roomPlayersCurrent+' / '+roomDataResults.roomPlayersMax}</span>
                    <span>{roomDataResults.roomMapName}</span>
                    <span>{roomDataResults.roomState}</span>
                  </>
                :null
              }
            </RoomRow>
          </RoomList>

          <Slots one>
            {
              (roomDataResults) ?
                <Slot header>
                  <span>Username</span>
                  <span>Character</span>
                  <span>Role</span>
                  <span>Level</span>
                  <span></span>
                </Slot>
              :null
            }
            {
              (roomDataResults) ?
                Array.isArray(roomDataResults.teamOne) && roomDataResults.teamOne.map((row, index) => (
                  <Slot key={index}>
                    <span>{row.userName}</span>
                    <span>{row.characterId}</span>
                    <span>{row.role}</span>
                    <span>{row.level}</span>
                    <span>
                      {
                        (row.userId === UserFunctions.getUserId()) && (
                          <Button text={"Leave Team"} callback={() => leaveTeam(1)}/>
                        )
                      }
                    </span>
                  </Slot>
                ))
              :null
            }
            {
              roomDataResults &&
              (!roomDataResults.teamOne ||
                !roomDataResults.teamOne.find(row => row.userId === UserFunctions.getUserId())) && (
                <Slot>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <Button text={"Join Team"} callback={() => joinTeam(1, 1)}/>
                </Slot>
              )
            }
          </Slots>

          <CharactersList>
            {isJoinedTeam && Array.isArray(charactersDataResults) && charactersDataResults.map((row, index) => (
                <Character key={index}>
                  <span>{row.characterId}</span>
                  <span>{row.characterName}</span>
                  <span>{row.characterRoleId}</span>
                  <span>{row.characterOwner}</span>
                  <Button text={"Choose"} callback={() => chooseCharacter(row.characterId)}/>
                </Character>
              ))}
          </CharactersList>

          <Slots two>
            {
              (roomDataResults) ?
                <Slot header>
                  <span>Username</span>
                  <span>Character</span>
                  <span>Role</span>
                  <span>Level</span>
                  <span></span>
                </Slot>
              :null
            }
            {
              (roomDataResults) ?
                Array.isArray(roomDataResults.teamTwo) && roomDataResults.teamTwo.map((row, index) => (
                  <Slot key={index}>
                    <span>{row.userName}</span>
                    <span>{row.characterId}</span>
                    <span>{row.role}</span>
                    <span>{row.level}</span>
                    <span>
                      {
                        (row.userId === UserFunctions.getUserId()) && (
                          <Button text={"Leave Team"} callback={() => leaveTeam(2)}/>
                        )
                      }
                    </span>
                  </Slot>
                ))
              :null
            }
            {
              roomDataResults &&
              (!roomDataResults.teamTwo ||
                !roomDataResults.teamTwo.find(row => row.userId === UserFunctions.getUserId())) && (
                <Slot>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <Button text={"Join Team"} callback={() => joinTeam(2, 1)}/>
                </Slot>
              )
            }
          </Slots>

          <Slots spectate>
              {
                (roomDataResults) ?
                  <Slot header>
                    <span>Username</span>
                    <span>Character</span>
                    <span>Role</span>
                    <span>Level</span>
                    <span></span>
                  </Slot>
                :null
              }
              {
                (roomDataResults) ?
                  Array.isArray(roomDataResults.spectate) && roomDataResults.spectate.map((row, index) => (
                    <Slot key={index}>
                      <span>{row.userName}</span>
                      <span>{row.character}</span>
                      <span>{row.role}</span>
                      <span>{row.level}</span>
                      <span>
                        {
                          (row.userId === UserFunctions.getUserId()) && (
                            <Button text={"Leave Spectate"} callback={() => leaveSpectate()}/>
                          )
                        }
                      </span>
                    </Slot>
                  ))
                :null
              }
              {
                roomDataResults &&
                (!roomDataResults.spectate ||
                  !roomDataResults.spectate.find(row => row.userId === UserFunctions.getUserId())) && (
                  <Slot>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <Button text={"Join Spectate"} callback={() => joinSpectate()}/>
                  </Slot>
                )
              }
            </Slots>

          <ButtonGroup>
            {
              (showStartButton === true) ?
                <Button text={'Start'} callback={() => joinBattle('start')} />
              :null
            }
            <Button text="Leave Room" callback={leaveRoom} />
          </ButtonGroup>
        </RoomWrapper>
      </Wrapper>
    </>
  );
};

export default Room;