import React from 'react';
//Style
import { Default, Error, Success } from './GetText.style';
//Hooks
import { useGetTextFetch } from '../../hooks/useGetTextFetch';
//Functions
import UserFunctions from '../UserFunctions';

const GetText = ({ textName, textLang, textStyle, valueAfter }) => {

    if(!textLang) {
        textLang = UserFunctions.getUserLanguage();
    }

    const {
        state,
        loading, 
        error,
    } = useGetTextFetch(textName, textLang,);
    

    if(loading) return 'Loading ...'
    if(error) return <div>Something went wrong...</div>

    return (
        <>
          {
            (!textStyle) ? 
              <>
                {(state.text != null) && state.text}
                {(valueAfter != null) && ' ' + valueAfter}
              </>
            : null
          }
          {
            (textStyle === 'default') ?
              <Default>
                {(state.text != null) && state.text}
                {(valueAfter != null) && ' ' + valueAfter}
              </Default>
            : null
          }
          {
            (textStyle === 'success') ?
              <Success>
                {(state.text != null) && state.text}
                {(valueAfter != null) && ' ' + valueAfter}
              </Success>
            : null
          }
          {
            (textStyle === 'error') ?
              <Error>
                {(state.text != null) && state.text}
                {(valueAfter != null) && ' ' + valueAfter}
              </Error>
            : null
          }
        </>
      );
      
};

export default GetText;