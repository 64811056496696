import {
  API_URL,
  //API_KEY,
} from './config';

const apiSettings = {

  fetchCharacter: async (userHash) => {

    var apiRequest = 'character';
    return await (await fetch(`${API_URL}getRequest?API=${apiRequest}&userHash=${userHash}`)).json();

  },
  fetchInventory: async (userHash) => {

    var apiRequest = 'inventory';
    return await (await fetch(`${API_URL}getRequest?API=${apiRequest}&userHash=${userHash}`)).json();

  },
  fetchSystem: async () => {
    const endpoint = `${API_URL}system`;
    return await (await fetch(endpoint)).json();
  },
handleEntry: async (action, entryId, entryValue, entryAmount, userHash) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'handleEntry', action: action, entryId: entryId, entryValue: entryValue, entryAmount: entryAmount, userHash: userHash })
  };
  return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();

},
handleList: async (action, entryListId, entryListValue, handleListShop, entryListCode, userHash) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'handleList', action: action, entryListId: entryListId, entryListValue: entryListValue, handleListShop: handleListShop, entryListCode: entryListCode, userHash: userHash })
  };
  return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();

},
  fetchCharacters: async (userHash) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'getCharacters', userHash: userHash })
    };

    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();

  },
  fetchRooms: async (userHash) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'getRooms', userHash: userHash })
    };

    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();

  },
  fetchRoom: async (roomHash, userHash) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'getRoom', roomHash: roomHash, userHash: userHash })
    };

    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();

  },
  joinRoom: async (roomHash, userHash) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'joinRoom', roomHash: roomHash, userHash: userHash })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();
    
  },
  leaveRoom: async (roomHash, userHash) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'leaveRoom', roomHash: roomHash, userHash: userHash })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();
    
  },
  joinTeam: async (roomHash, userHash, teamId, teamSlot) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'joinTeam', roomHash: roomHash, userHash: userHash, teamId: teamId, teamSlot: teamSlot })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();
    
  },
  leaveTeam: async (roomHash, userHash, teamId) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'leaveTeam', roomHash: roomHash, userHash: userHash, teamId: teamId })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();
    
  },
  joinSpectate: async (roomHash, userHash) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'joinSpectate', roomHash: roomHash, userHash: userHash })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();
    
  },
  leaveSpectate: async (roomHash, userHash) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'leaveSpectate', roomHash: roomHash, userHash: userHash })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();
    
  },
  chooseCharacter: async (roomHash, userHash, characterId) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'chooseCharacter', roomHash: roomHash, userHash: userHash, characterId: characterId })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();
    
  },
  joinBattle: async (roomHash, userHash) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'joinBattle', roomHash: roomHash, userHash: userHash })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();
    
  },
  fetchBattle: async (roomHash, userHash) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'fetchBattle', roomHash: roomHash, userHash: userHash })
    };

    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();

  },
  fetchShops: async () => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'getShops' })
    };

    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();

  },
  fetchList: async (listId, userHash) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'getList', listId: listId, userHash: userHash })
    };

    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();

  },

  fetchListEntry: async (listId, userHash) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'getListEntry', userHash: userHash, listId: listId })
    };

    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();

  },
  fetchUnits: async () => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'getUnits' })
    };

    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();

  },
  fetchUser: async (userName, userHash) => {
    const endpoint = `${API_URL}user?userName=${userName}&userHash=${userHash}`;
    return await (await fetch(endpoint)).json();
  },
  fetchText: async (textName, textLang) => {
    const endpoint = `${API_URL}content?textName=${textName}&textLang=${textLang}`;
    return await (await fetch(endpoint)).json();
  },
  fetchStats: async (userHash) => {
    const endpoint = `${API_URL}stats?userHash=${userHash}`;
    return await (await fetch(endpoint)).json();
  },
  vote: async (vote, answerId, userHash) => {
    const resultVote = `${API_URL}vote?vote=${vote}&answerId=${answerId}&userHash=${userHash}`;
    return await (await fetch(resultVote)).json();
  },
  moderation: async (action,
                      postId,
                      answerId,
                      userHash,
                      reportId,
                      postTitle,
                      postAnswer,
                      entryId,
                      entryListId
                    ) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'moderation', action: action,
                                                postId: postId,
                                                answerId: answerId,
                                                userHash: userHash,
                                                reportId: reportId,
                                                post: postTitle,
                                                answer: postAnswer,
                                                entryId: entryId,
                                                entryListId: entryListId })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();

  },
  updateSettings: async (action, userName, userHash, userNameNew, email, passwordOld, passwordNew, darkMode) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'updateSettings', action: action, userName: userName, userHash: userHash, userNameNew: userNameNew, userEmailNew: email, userPasswordOld: passwordOld, userPasswordNew: passwordNew, userDarkMode: null })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();
    
  },
  createRoom: async (roomDescription, userHash) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'createRoom', roomDescription: roomDescription, userHash: userHash })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();
    
  },
  createCharacter: async (action, characterId, characterName, characterRoleId, userHash) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'createCharacter', action: action, characterId: characterId, characterName: characterName, characterRoleId, characterRoleId, userHash: userHash })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();
    
  },
  register: async (action, username, password, email) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'authentication', action: action, username: username, password: password, email: email })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();
    
  },
  login: async (username, password, code, authenticatorPin) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'authentication', action: 'login', username: username, password: password, emailCode: code, authenticatorPin: authenticatorPin })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();
    
  },
  logout: async (userId, userHash) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'authentication', action: 'logout', userId: userId, userHash: userHash })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();

  },
  accountRecovery: async (email, code, newPassword) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'accountRecovery', action: 'accountRecovery', email: email, code: code, newPassword: newPassword })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();

  },
};

export default apiSettings;