import styled from 'styled-components';

export const RoomWrapper = styled.div`
  width: 100%;
  border: 3px solid;
  border-color: var(--color-border-1);
  border-radius: 12px;
  padding: 16px;
`;

export const RoomList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const RoomRow = styled.li`
  display: grid;
  grid-template-columns: 2fr 3fr 1fr 1fr 1fr;
  gap: 16px;
  padding: 8px 0;
  border-bottom: 1px solid var(--color-border-1);
  font-size:var(--fontSmall);

  ${(props) =>
    props.header &&
    `
    font-weight: bold;
    text-transform: uppercase;
  `}
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

export const Slot = styled.li`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr 2fr;
  gap: 16px;
  padding: var(--padding-all);
  border-bottom: 1px solid var(--color-border-1);
  text-align:center;
  background:var(--color-bg-4);
  font-size:var(--fontSuperSmall);

  ${(props) =>
    props.header &&
    `
    font-weight: bold;
    text-transform: uppercase;
  `}
`;

export const Slots = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align:center;
  font-size:var(--fontSuperSmall);

  button {
    background:green;
    padding:0px;
    margin:0px;
    font-size:var(--fontSuperSmall);
  }

  ${(props) =>
    props.one &&
    `
    width:30%;
    float:left;
  `}
  ${(props) =>
    props.two &&
    `
    width:30%;
    float:right;
  `}
  ${(props) =>
    props.spectate &&
    `
    width:40%;
    margin:auto;
  `}
`;

export const CharactersList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  width:40%;
  float:left;
`;

export const Character = styled.li`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr;
  gap: 16px;
  padding: var(--padding-all);
  border-bottom: 1px solid var(--color-border-1);

  ${(props) =>
    props.header &&
    `
    font-weight: bold;
    text-transform: uppercase;
  `}
`;