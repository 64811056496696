import React from 'react';
import { Wrapper } from './PopUp.styles';

const PopUp = ({children}) => {


    return (   
        <Wrapper>
            {children}
        </Wrapper>
    )
};
export default PopUp;