import { useState, useEffect } from 'react';
// API
import API from '../API';

export const useRoomsFetch = (userHash) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        const fetchRooms = async() => {
            try {
                setLoading(true);
                setError(false);

                const data = await API.fetchRooms(userHash);
            
                setData({
                    ...data,
                    results: [...data.results]
                })

            } catch (error) {
                setError(true);
            }
            setLoading(false);
        };

        fetchRooms();
    }, [userHash, reload])

    return { data, loading, error, setReload };
}