import React from 'react';
import { useNavigate} from 'react-router-dom';
//Mobile Check
import {isMobile} from 'react-device-detect';
// Components
import Spinner from './Spinner';
import Header from './Header';
//Styles
import { Wrapper as HomeWrapper } from './Home/Home.styles';
//Functions
import UserFunctions from './UserFunctions';


const Home = () => {


    const navigate = useNavigate();

    const loading = false;

    return (
        <>
            <Header userName={UserFunctions.getUserName()} />
        
            <HomeWrapper id='homeWrapper'>
                {
                    loading ?
                        <Spinner hidden={false} />
                    :
                        <Spinner hidden={true} />
                }
            </HomeWrapper>
        </>
    )
};
export default Home;