import React, {useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API from '../API';
import { isMobile } from 'react-device-detect';
import Spinner from './Spinner';
import Header from './Header';
import Button from './Button';
import Input from './Input';
import Wrapper from './Wrapper';
import PopUp from './PopUp';
import { LobbyWrapper, LobbyList, LobbyRow, SearchField, ButtonGroup, CreateLobby } from './Lobby/Lobby.style';
import UserFunctions from './UserFunctions';

//Hooks
import { useRoomsFetch } from '../hooks/useRoomsFetch';
import { useRoomFetch } from '../hooks/useRoomFetch';

const Lobby = () => {
  const navigate = useNavigate();

  const {
    roomHash
  } = useParams();

  //Get Rooms
    const {
      data,
      setReload : setReloadRoomsFetch
  } = useRoomsFetch(UserFunctions.getSessionHash());

  //Get Room Data based on roomHash, userHash
  const {
    roomData
  } = useRoomFetch(roomHash, UserFunctions.getSessionHash());

  //Check if reconnect to the room is necessarry
  if(roomData.roomHash) {
    navigate('/room/'+roomData.roomHash);
  }


  //Create a new Room
  const [showCreateRoom, setShowCreateRoom] = useState(false);
  const [roomDescription, setRoomDescription] = useState('')
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleInput = e => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;

    if(name === 'roomDescription') setRoomDescription(value)
  }

  const createRoom = async () => {
      try {
        setError(false);
        setLoading(true);

        const apiRoom = await API.createRoom(roomDescription, UserFunctions.getSessionHash());

        var roomHash = apiRoom.results[0].roomHash;

        if(roomHash) {
          //Success
          UserFunctions.setRoomHash(roomHash);
          navigate('/room/'+roomHash);
        }
        else
        {
          //Error
            //if(resultUserId) {
                //UserFunctions.setUserId(resultUserId);
            //}

        }
    } catch (error) {
        setError(true);
    }
    setLoading(false);
  };
  //Create a new Room End

  //Join a Room
  const joinRoom = async (roomHash) => {
    try {
      if (roomHash) {
        const apiRoom = await API.joinRoom(roomHash, UserFunctions.getSessionHash());


        var roomHash = apiRoom.results[0].roomHash;
        var roomFull = apiRoom.results[0].roomFull;
        var roomError = apiRoom.results[0].roomError;

        if(roomError === false) {
          if (roomFull === false) {
            if (roomHash) {
              //Success
              UserFunctions.setRoomHash(roomHash);
              navigate('/room/' + roomHash);
            }
          } else {
            alert('This room is full!');
          }
        } else {
          alert('There was an Error!');
        }
      }
    } catch (error) {
      setError(true);
    }
  };
  //Join a Room End

  useEffect(() => {
    const intervalId = setInterval(() => {
        setReloadRoomsFetch(prev => !prev); // Trigger a reload of the useRoomFetch hook
    }, 5000); // Interval for 5 seconds

    // Cleanup function to be called when the component unmounts:
    return () => {
        clearInterval(intervalId);
    };
}, []);

  return (
    <>
      <Header userName={UserFunctions.getUserName()} />
      <Wrapper>
        <LobbyWrapper>
            {
              (showCreateRoom == true) ? 
                <PopUp>
                    <center>
                      <br></br>
                    <Input type='text' placeholder='Room Description' name={'roomDescription'} onChange={handleInput}/>
                    {
                      loading ?
                        <Spinner />
                      :
                      <Button text={'Create'} callback={createRoom} />
                    }
                    
                    <Button text={'Close'} callback={() => setShowCreateRoom(false)} />
                    </center>
                </PopUp>
              :null
            }
          <LobbyList>
            <LobbyRow header>
              <span></span>
              <span>Game</span>
              <span>Description</span>
              <span>Players</span>
              <span>Map</span>
              <span>Status</span>
              <span></span>
            </LobbyRow>
            {Array.isArray(data.results) && data.results.map((lobby, index) => (
              <LobbyRow key={index}>
                {
                  // Is it private? no roomHash = private
                  (lobby.roomHash) ?
                    <Button className='joinButton' text={<i className="fa-solid fa-right-to-bracket"></i>} callback={() => joinRoom(lobby.roomHash)} />
                  :
                    <Button className='joinButton' text={<i className="fa-solid fa-lock"></i>} />
                }
                <span>{lobby.roomLead}</span>
                <span>{lobby.roomDescription}</span>
                <span>{lobby.roomPlayersCurrent+' / '+lobby.roomPlayersMax}</span>
                <span>{lobby.roomMapName}</span>
                <span>{lobby.roomState}</span>
              </LobbyRow>
            ))}
          </LobbyList>
          <ButtonGroup>
            <Button text="Create Custom Room" callback={() => setShowCreateRoom(true)}/>
          </ButtonGroup>
        </LobbyWrapper>
      </Wrapper>
    </>
  );
};

export default Lobby;