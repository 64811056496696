// Configuration for TMDB API
// Read more about the API here: https://developers.themoviedb.org/

const API_URL = 'https://api.derlippo.com/api/';
const IMG_URL = 'https://api.derlippo.com/api/uploads/';
const USER_IMG_URL = 'https://api.derlippo.com/api/uploads/';
//const API_KEY = process.env.REACT_APP_API_KEY;
//const API_KEY = '';

const SEARCH_BASE_URL = `${API_URL}search?language=en-US&query=`;
const POSTS_BASE_URL = `${API_URL}posts?language=en-US`;


export {
  SEARCH_BASE_URL,
  POSTS_BASE_URL,
  IMG_URL,
  API_URL,
  USER_IMG_URL,
  //API_KEY,
};
