import styled from "styled-components";

export const Wrapper = styled.div`
position: relative;
overflow: hidden;
width: 100%;
height: 100vh;
background: black;

  canvas {
    width:100%;
    border: 1px solid black;
    
  }
`;