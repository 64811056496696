import { useState, useEffect } from 'react';
// API
import API from '../API';

export const useRoomFetch = (roomHash, userHash) => {
  const [roomData, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const fetchRoom = async () => {
      try {
        setLoading(true);
        setError(false);

        const data = await API.fetchRoom(roomHash, userHash);

        setData({
          ...data,
          results: [...data.results],
        });
      } catch (error) {
        setError(true);
      }
      setLoading(false);
    };

    fetchRoom();
  }, [roomHash, userHash, reload]);

  return { roomData, loading, error, setReload };
};
