import React from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
//Mobile Check
import {isMobile} from 'react-device-detect';
// Components
import Spinner from './Spinner';
import Header from './Header';
//Styles
import { Wrapper as BasarWrapper } from './Basar/Basar.styles';
//Functions
import UserFunctions from './UserFunctions';


const Basar = () => {

    const {
        search
    } = useParams();

    const navigate = useNavigate();


    return (
        <>
            <Header userName={UserFunctions.getUserName()} />
        
            <BasarWrapper id='basarWrapper'>

            </BasarWrapper>

        </>
    )
};
export default Basar;