import { useState, useEffect } from 'react';
// API
import API from '../API';

const initialState = {
    results: []
};

export const useCharacterFetch = (userHash) => {
    const [state, setState] = useState(initialState);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [reload, setReload] = useState(false);
    const [characterId, setCharacterId] = useState(null);

    useEffect(() => {
        const fetchCharacter = async() => {
            try {
                setLoading(true);
                setError(false);

                const post = await API.fetchCharacter(userHash, characterId);
                
                setState({
                    ...post,
                    results: [...post.results]
                })

                setLoading(false);

            } catch (error) {
                setError(true);
            }
        };

        fetchCharacter();
    }, [userHash, characterId, reload])

    return { state, loading, error, setReload, setCharacterId };
}