import React from 'react';
//Routing
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//Mobile Check
import {isMobile} from 'react-device-detect';
//Style
import { GlobalStyle } from './GlobalStyle';
// API
import API from './API';
//Hooks
import { useSystemFetch } from './hooks/useSystemFetch';
//Components
import Home from './components/Home';
import Battle from './components/Battle';
import Character from './components/Character';
import Inventory from './components/Inventory';
import Basar from './components/Basar';
import Search from './components/Search';
import NotFound from './components/NotFound';
import Maintenance from './components/Maintenance';
import Lobby from './components/Lobby';
import Room from './components/Room';
import Login from './components/Login';
import Logout from './components/Logout';
import Register from './components/Register';
import AccountRecovery from './components/AccountRecovery';
import Legal from './components/Legal';
import Profile from './components/Profile';
import ScrollToTop from './components/ScrollToTop'
import Settings from './components/Settings';

import Testing from './components/Testing';
// Context
import UserProvider from './context';
//Function
import UserFunctions from './components/UserFunctions';

const App = () => {
  
  const {
    state,
    loading, 
    error,
  } = useSystemFetch();

  if(loading) return ''
  if(error) return <div>Something went wrong...</div>

  return (
    <Router>
      <UserProvider>
        <ScrollToTop>
          {
            (state.maintenance == 1) ?
              <Routes>
                <Route path='*' element={<Maintenance />} />
              </Routes>
            :null
          }
          {
            (state.maintenance == 0) ?
                (UserFunctions.getSessionHash()) ?
                  <Routes>
                    <Route path='/' element={<Home />} />
                    
                    <Route path='/logout' element={<Logout />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/login/:code' element={<Login />} />
                    <Route path='/register' element={<Register />} />

                    <Route path='/account-recovery' element={<AccountRecovery />} />
                    <Route path='/account-recovery/:code' element={<AccountRecovery />} />

                    <Route path='/legal/:legalName' element={<Legal />} />

                    <Route path='/battle' element={<Battle />} />
                    <Route path='/lobby' element={<Lobby />} />
                    <Route path='/lobby/:roomHash' element={<Lobby />} />
                    <Route path='/room/:roomHash' element={<Room />} />
                    <Route path='/character' element={<Character />} />
                    <Route path='/inventory' element={<Inventory />} />
                    <Route path='/basar' element={<Basar />} />

                    <Route path='/search' element={<Search />} />

                    <Route path="/user/:userName" element={<Profile />} />
                    <Route path='/settings' element={<Settings />} />

                    <Route path='/!:hashtag' element={<Basar />} />
                    <Route path='/search/:search' element={<Basar />} />

                    <Route path='/testing' element={<Testing />} />
                    
                    <Route path='*' element={<NotFound />} />
                  </Routes>
                :
                  <Routes>
                    <Route path='/' element={<Login />} />

                    <Route path='/logout' element={<Logout />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/login/:code' element={<Login />} />
                    <Route path='/register' element={<Register />} />

                    <Route path='/account-recovery' element={<AccountRecovery />} />
                    <Route path='/account-recovery/:code' element={<AccountRecovery />} />

                    <Route path='/legal/:legalName' element={<Legal />} />

                    <Route path='*' element={<NotFound />} />
                  </Routes>
            :null
          }
        </ScrollToTop>
        <GlobalStyle />
      </UserProvider>
    </Router>
  )
};

export default App;