import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import UserFunctions from './UserFunctions';
import Header from './Header';
import Wrapper from './Wrapper';
import PopUp from './PopUp';
import { Wrapper as BattleWrapper } from './Battle/Battle.styles';
import { useBattleFetch } from '../hooks/useBattleFetch';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import Button from './Button';
import GetText from './GetText';

const Battle = () => {

  const navigate = useNavigate();

  if(UserFunctions.getRoomHash() === null) {
    navigate('/lobby');
  }

  const {
    state
  } = useBattleFetch(UserFunctions.getSessionHash());

  const [socketUrl, setSocketUrl] = useState('wss://api.derlippo.com:8080');
  const [messageHistory, setMessageHistory] = useState([]);

  const didUnmount = useRef(false);

  const sendWebsocketData = {
    userHash: UserFunctions.getSessionHash(),
    roomHash: UserFunctions.getRoomHash(),
    characterId: null,
    action: null
  };

  /* Create Websocket */
    // Pass socketUrl directly to useWebSocket
    const {
      sendMessage,
      lastMessage : websocketReceivedData,
      readyState,
    } = useWebSocket(socketUrl, {
      shouldReconnect: (closeEvent) => {
        return true; // Enables built-in reconnect mechanism
      },
      onClose: (event) => {
        console.log('WebSocket closed:', event);
      },
    });

    useEffect(() => {
      if (websocketReceivedData !== null) {
        setMessageHistory((prev) => prev.concat(websocketReceivedData));
      }
    }, [websocketReceivedData, setMessageHistory]);

    useEffect(() => {
      return () => {
        didUnmount.current = true;
      };
    }, []);

    // Custom reconnect mechanism
    useEffect(() => {
      if (readyState === ReadyState.CLOSED && !didUnmount.current) {
        const timer = setTimeout(() => {
          setSocketUrl((prevUrl) => `${prevUrl}`);
        }, 1000); // Reconnect interval: 1000 ms (1 seconds)

        return () => {
          clearTimeout(timer);
        };
      }
    }, [readyState]);


    const connectionStatus = {
      [ReadyState.CONNECTING]: 'Connecting',
      [ReadyState.OPEN]: 'Open',
      [ReadyState.CLOSING]: 'Closing',
      [ReadyState.CLOSED]: 'Closed',
      [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];
  /* Create Websocket End */

  /* Game Logic */
  const [playerData, setPlayerData] = useState([
    {
      team: "left",
      players: [
         // { characterId: 0, userId: 0, userName: "Dummy", role: "", image: `${process.env.PUBLIC_URL}/images/characterRole/1.png`, maxHealth: 100, currentHealth: 0 }, 
      ],
    },
    {
      team: "right",
      players: [
         // { characterId: 0, userId: 0, userName: "Dummy", role: "", image: `${process.env.PUBLIC_URL}/images/enemies/1.png`, maxHealth: 100, currentHealth: 0 }, 
      ],
    },
  ]);

  // Check if Preload is needed
  const hasRealPlayers = playerData.some(team => team.players.filter(player => player.userId !== 0).length > 0);

  useEffect(() => {
    if((hasRealPlayers === false) && (connectionStatus === 'Open')) {
        sendWebsocketData['action'] = 'preload';
        sendMessage(JSON.stringify(sendWebsocketData));
    }
  }, [hasRealPlayers, connectionStatus]);
  // Check if Preload is needed End

  // Receive Data from Websocket and Update Game Data
  useEffect(() => {
    if (websocketReceivedData !== null && websocketReceivedData.data !== null) {
      const serverData = JSON.parse(websocketReceivedData.data);
      console.log(websocketReceivedData);

      if(serverData !== null) {

        // Interpret the server data and format it as needed.
        const formattedData = [
          {
            team: "left",
            players: serverData[0].teamOne.map(player => ({
              characterId: player.characterId,
              userId: player.userId,
              userName: player.userName,
              role: player.role || "Unknown", // default to "Unknown" if role is null
              image: `${process.env.PUBLIC_URL}/images/characterRole/1.png`,
              maxHealth: player.characterMaxHealth,
              currentHealth: player.characterCurrentHealth
            }))
          },
          {
            team: "right",
            players: serverData[0].teamTwo.map(player => ({
              characterId: player.characterId,
              userId: player.userId,
              userName: player.userName,
              role: player.role || "Unknown", // default to "Unknown" if role is null
              image: `${process.env.PUBLIC_URL}/images/enemies/1.png`,
              maxHealth: player.characterMaxHealth,
              currentHealth: player.characterCurrentHealth
            }))
          }
        ];
    
        // Update the state with the new data.
        setPlayerData(formattedData);
      }
    
    }
  }, [websocketReceivedData]);  
  // Receive Data from Websocket and Update Game Data End


    //Send Data to Websocket
    function targetAction(action, characterId) {
      sendWebsocketData['action'] = action; //Save Action Data
      sendWebsocketData['characterId'] = characterId; //Save Target Data
      
      sendMessage(JSON.stringify(sendWebsocketData));
    }
    // Send Data to Websocket End
  /* Game Logic End */

/* Canvas */
const baseResolutionWidth = 1920;
const baseResolutionHeight = 1080;

const canvasRef = useRef(null);
const playerImageRefs = useRef(new Map()).current;
const allImagesLoaded = useRef(false);

useEffect(() => {
  const resizeCanvas = () => {
    if (!canvasRef.current) {
      return;
    }

    const baseRatio = baseResolutionWidth / baseResolutionHeight;
    const canvas = canvasRef.current;

    let width = document.body.offsetWidth;
    let height = document.body.offsetHeight;

    const containerRatio = width / height;

    if (containerRatio > baseRatio) {
      width = height * baseRatio;
    } else {
      height = width / baseRatio;
    }

    canvas.width = width;
    canvas.height = height;

    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
    canvas.style.position = 'absolute';
    canvas.style.top = '50%';
    canvas.style.left = '50%';
    canvas.style.transform = 'translate(-50%, -50%)';

    drawCanvas(); // Call the function to redraw canvas here
  };

  window.addEventListener('resize', resizeCanvas);
  resizeCanvas();  // Initial resizing

  return () => window.removeEventListener('resize', resizeCanvas);
}, []);

useEffect(() => {
  if (allImagesLoaded.current) {
    return;
  }

  playerData.forEach((team, teamIndex) => {
    team.players.forEach((player, playerIndex) => {
      if (!playerImageRefs.has(player.image)) {
        const playerImage = new Image();
        playerImage.src = player.image;
        playerImage.onload = () => {
          playerImageRefs.set(player.image, playerImage);

          let allLoaded = true;
          playerData.forEach((team, teamIndex) => {
            team.players.forEach((player, playerIndex) => {
              if (!playerImageRefs.has(player.image)) {
                allLoaded = false;
              }
            });
          });

          if (allLoaded) {
            allImagesLoaded.current = true;
            drawCanvas(); // Redraw the canvas after all images are loaded
          }
        };
      }
    });
  });
}, [playerData]);

const drawCanvas = () => {
  if (!allImagesLoaded.current) {
    return;
  }

  const canvas = canvasRef.current = canvasRef.current || document.createElement('canvas');
  const ctx = canvas.getContext("2d");

  canvas.width = baseResolutionWidth;
  canvas.height = baseResolutionHeight;

  ctx.clearRect(0, 0, canvas.width, canvas.height);

  const getFontSize = (baseSize, baseResolution, actualResolution) => {
    return baseSize * (actualResolution / baseResolution);
  };

  const bgImageSrc = `${process.env.PUBLIC_URL}/images/battle/1.jpeg`;

  const drawBackground = () => {
    const bgImage = new Image();
    bgImage.src = bgImageSrc;
    bgImage.onload = () => {
      ctx.drawImage(bgImage, 0, 0, canvas.width, canvas.height);
      drawPlayers();  // Draw the players AFTER the background image is loaded.
    };
  };

  const drawPlayers = () => {
    playerData.forEach((team, teamIndex) => {
      team.players.forEach((player, playerIndex) => {

        // Calculate the Player xPos
        let startXPos;
        if(teamIndex === 0) {
          startXPos = 0;
        } else if (teamIndex === 1) {
          startXPos = canvas.width;
        } else {
          // setup startXPos for teamIndex === 2
        }

        const xPos = startXPos;

        // Calculate the Player yPos
        // 20 = Prevent first player spawning at the corner
        // 220 = the space between each player
        const yPos = (20 + playerIndex * 220);

        if(team.team === 'left') {

            //Char
            ctx.fillStyle = "white";
            ctx.fillRect(xPos, yPos, 500, 150);
            //Char End

            //Char Image
            if (!playerImageRefs.has(player.image)) {
              const playerImage = new Image();
              playerImage.src = player.image;
              playerImage.onload = () => {
                ctx.drawImage(playerImage, xPos, yPos, 150, 150);
              };
              playerImageRefs.set(player.image, playerImage);
            } else {
              const playerImage = playerImageRefs.get(player.image);
              ctx.drawImage(playerImage, xPos, yPos, 150, 150);
            }   
            //Char Image End

            //Char Text
            ctx.fillStyle = "black";
            ctx.font = `${getFontSize(26, baseResolutionWidth, canvas.width)}px Arial`;
            ctx.fillText(player.userName, (xPos + 170), (yPos + 28));
            ctx.font = `${getFontSize(20, baseResolutionWidth, canvas.width)}px Arial`;
            ctx.fillText(player.role, (xPos + 170), (yPos + 56));
            //Char Text End

            //Char Life
              const totalHealth = 100; // the maximum health

              // The length of the health bar
              const healthBarLength = 300;

              // Calculate the length of the red and green parts based on the player's health
              const greenLength = (player.currentHealth / totalHealth) * healthBarLength;
              const redLength = healthBarLength - greenLength; // The rest of the bar is red

              ctx.fillStyle = "green";
              ctx.fillRect((xPos + 170), (yPos + 65), greenLength, 30);

              ctx.fillStyle = "red";
              ctx.fillRect((xPos + 170 + greenLength), (yPos + 65), redLength, 30);
            //Char Life End

            if(UserFunctions.getUserId() !== player.userId) {
              // Attack button
              ctx.fillStyle = 'white'
              ctx.fillRect((xPos + 685), (yPos+10), -150, 50)

              ctx.fillStyle = "black";
              ctx.font = `${getFontSize(20, baseResolutionWidth, canvas.width)}px Arial`;
              ctx.fillText('Attack', (xPos + 565), yPos + 40);

              ctx.strokeStyle = 'black';  // Set the border color
              ctx.lineWidth = 3;  // Set the border width

              ctx.strokeRect(xPos + 685, yPos+10, -150, 50);  // Draw the border around the rectangle
            }

          } else if (team.team === 'right') {
            //Char
            ctx.fillStyle = "white";
            ctx.fillRect((xPos), yPos, -500, 150)

            //Char Image
            if (!playerImageRefs.has(player.image)) {
              const playerImage = new Image();
              playerImage.src = player.image;
              playerImage.onload = () => {
                ctx.drawImage(playerImage, xPos, yPos, -150, 150);
              };
              playerImageRefs.set(player.image, playerImage);
            } else {
              const playerImage = playerImageRefs.get(player.image);
              ctx.drawImage(playerImage, xPos, yPos, -150, 150);
            }                  
            //Char Image End

            //Char Text
            ctx.fillStyle = "black";
            ctx.font = `${getFontSize(26, baseResolutionWidth, canvas.width)}px Arial`;
            ctx.fillText(player.userName, (xPos - 470), (yPos + 28));
            ctx.font = `${getFontSize(20, baseResolutionWidth, canvas.width)}px Arial`;
            ctx.fillText(player.role, (xPos - 470), (yPos + 56));
            //Char Text End

            //Char Life
              const totalHealth = 100; // the maximum health

              // The length of the health bar
              const healthBarLength = 300;

              // Calculate the length of the red and green parts based on the player's health
              const greenLength = (player.currentHealth / totalHealth) * healthBarLength;
              const redLength = healthBarLength - greenLength; // The rest of the bar is red

              ctx.fillStyle = "green";
              ctx.fillRect((xPos - 470), (yPos + 65), greenLength, 30);

              ctx.fillStyle = "red";
              ctx.fillRect((xPos - 470 + greenLength), (yPos + 65), redLength, 30);
            //Char Life End

            if(UserFunctions.getUserId() !== player.userId) {
              // Attack button
              ctx.fillStyle = 'white'
              ctx.fillRect((xPos - 525), yPos + 10, -150, 50)

              ctx.fillStyle = "black";
              ctx.font = `${getFontSize(20, baseResolutionWidth, canvas.width)}px Arial`;
              ctx.fillText('Attack', (xPos - 655), yPos-(-40));

              ctx.strokeStyle = 'black';  // Set the border color
              ctx.lineWidth = 3;  // Set the border width

              ctx.strokeRect(xPos - 525, yPos + 10, -150, 50);  // Draw the border around the rectangle
            }
          }
      });
    });
  };
  drawBackground();
}
  
  useEffect(() => {
    drawCanvas();
  }, [playerData, allImagesLoaded.current]);

  /* Draw Canvas End */

  /* Use Canvas */
  
  const handleCanvasClick = (event) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const scaleX = canvasRef.current.width / rect.width;    // Verhältnis der nativen Auflösung zur aktuellen Auflösung
    const scaleY = canvasRef.current.height / rect.height;  // Verhältnis der nativen Auflösung zur aktuellen Auflösung
  
    const canvasRelativeX = (event.clientX - rect.left) * scaleX;
    const canvasRelativeY = (event.clientY - rect.top) * scaleY;
  
    playerData.forEach((team, teamIndex) => {
      team.players.forEach((player, playerIndex) => {
  
        // Calculate the Player xPos
        let startXPos;
        if(teamIndex === 0) {
          startXPos = 0;
        } else if (teamIndex === 1) {
          startXPos = canvasRef.current.width;
        } else {
          // setup startXPos for teamIndex === 2
        }
  
        const xPos = startXPos;
  
        // Calculate the Player yPos
        // 20 = Prevent first player spawning at the corner
        // 220 = the space between each player
        const yPos = (20 + playerIndex * 220); 
  
        if (team.team === 'left') {
          if (
            // Width 150
            canvasRelativeX >= xPos + 535 && // From
            canvasRelativeX <= xPos + 685 && // To
            // Height 50
            canvasRelativeY >= yPos + 10 && // From
            canvasRelativeY <= yPos + 60 // To
          ) {
            targetAction('attack', player.characterId);
          }
        } else if (team.team === 'right') {
          if (
            // Width 150
            canvasRelativeX >= xPos - 675 && // From
            canvasRelativeX <= xPos - 525 && // To
            // Height 50
            canvasRelativeY >= yPos + 10 && // From
            canvasRelativeY <= yPos + 60 // To
          ) {
            targetAction('attack', player.characterId);
          }
        }
      });
    });
  };
  
  
  /* Use Canvas End */

  /* Open Console */
  const [keyBuffer, setKeyBuffer] = useState('');
  const [showConsole, setShowConsole] = useState(false);

  const handleKeyDown = (event) => {
    setKeyBuffer(prev => prev + event.key);
  };

  // Event-Listener hinzufügen
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    // Event-Listener entfernen, wenn die Komponente unmounted wird
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  // Key-Buffer prüfen
  useEffect(() => {
    if (keyBuffer.toLowerCase().includes('open console')) {
      setShowConsole(true);
      setKeyBuffer(''); // Buffer leeren
    }
  }, [keyBuffer]);

  // Key-Buffer alle 15 Sekunden leeren
  useEffect(() => {
    const timerId = setInterval(() => {
      setKeyBuffer('');
    }, 15000);

    return () => {
      clearInterval(timerId); // Timer entfernen, wenn die Komponente unmounted wird
    }
  }, []);
  /* Open Console End */

  return (
    <>
      {
        //<Header userName={UserFunctions.getUserName()} />
      }
      <BattleWrapper>
        <canvas ref={canvasRef} onClick={handleCanvasClick} />
      </BattleWrapper>
      {
        (showConsole) ?
          <PopUp>
            <div>
              <span>The WebSocket is currently: <i>{connectionStatus}</i></span>
              <br></br>
              {websocketReceivedData ? <span>Last message: {websocketReceivedData.data}</span> : null}
              <br></br>
              <button
                onClick={() => targetAction('check', 0)}
                disabled={readyState !== ReadyState.OPEN}
              >
                Send
              </button>
              <br></br>
              <ul>
                {messageHistory.map((message, idx) => (
                  <><span key={idx}>{message ? message.data : null}</span><br></br></>
                ))}
              </ul>
            </div>
            <Button callback={() => setShowConsole(false)} text={<GetText textName='dismiss' />}></Button>
          </PopUp>
        : null
      }
    </>
  );
};

export default Battle;