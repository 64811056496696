import { useState, useEffect } from 'react';
// API
import API from '../API';

const initialState = {
    results: []
};

export const useBattleFetch = (userHash) => {
    const [state, setState] = useState(initialState);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchBattle = async() => {
            try {
                setLoading(true);
                setError(false);

                const post = await API.fetchBattle(userHash);
                
                setState({
                    ...post,
                    results: [...post.results]
                })

                setLoading(false);

            } catch (error) {
                setError(true);
            }
        };

        fetchBattle();
    }, [userHash])

    return { state, loading, error };
}