import React, { useEffect, useRef } from 'react';

const baseWidth = 1920;
const baseHeight = 1080;

const Game = () => {
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  
  const drawRectangles = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const rectWidth = canvas.width / 4;
    const rectHeight = canvas.height / 4;
    
    ctx.fillStyle = 'red';
    ctx.fillRect(0, 0, rectWidth, rectHeight);

    ctx.fillStyle = 'blue';
    ctx.fillRect(0, canvas.height - rectHeight, rectWidth, rectHeight);

    ctx.fillStyle = 'green';
    ctx.fillRect(canvas.width - rectWidth, 0, rectWidth, rectHeight);

    ctx.fillStyle = 'yellow';
    ctx.fillRect(canvas.width - rectWidth, canvas.height - rectHeight, rectWidth, rectHeight);
  }

  useEffect(() => {
    const resizeCanvas = () => {
      if (!containerRef.current) {
        return;
      }

      const baseRatio = baseWidth / baseHeight;
      const canvas = canvasRef.current;

      let width = containerRef.current.offsetWidth;
      let height = containerRef.current.offsetHeight;

      const containerRatio = width / height;

      if (containerRatio > baseRatio) {
        width = height * baseRatio;
      } else {
        height = width / baseRatio;
      }

      canvas.width = width;
      canvas.height = height;

      canvas.style.width = `${width}px`;
      canvas.style.height = `${height}px`;
      canvas.style.position = 'absolute';
      canvas.style.top = '50%';
      canvas.style.left = '50%';
      canvas.style.transform = 'translate(-50%, -50%)';

      drawRectangles();
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();  // Initial resizing

    return () => window.removeEventListener('resize', resizeCanvas);
  }, []);

  return (
    <div style={{ background: 'black' }}>
      <div ref={containerRef} style={{ position: 'relative', overflow: 'hidden', width: '100%', height: '100vh' }}>
        <canvas ref={canvasRef} style={{background: 'white'}}></canvas>
      </div>
    </div>
  );
};

export default Game;
