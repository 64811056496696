import React, { useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
//Mobile Check
import {isMobile} from 'react-device-detect';
//Functions
import UserFunctions from './UserFunctions';
// Components
import Header from './Header';
import Wrapper from './Wrapper'
//Styles
import { Wrapper as InventoryWrapper, InventoryNavigation, InventoryNavigationTab, InventoryContent, InventoryContentShop } from './Inventory/Inventory.styles';
//Hook
import { useInventoryFetch } from '../hooks/useInventoryFetch';


const Inventory = () => {

    const {
        state
    } = useInventoryFetch(UserFunctions.getSessionHash());

    const [inventoryNavigationTab, setInventoryNavigationTab] = useState('');


    return (
        <>
            <Header userName={UserFunctions.getUserName()} />
            <Wrapper>
                <InventoryWrapper>
                    <InventoryNavigation>
                        <InventoryNavigationTab className={(inventoryNavigationTab == 'Equip') ? `active` :null} onClick={() => setInventoryNavigationTab('Equip')}>Equip</InventoryNavigationTab>
                        <InventoryNavigationTab className={(inventoryNavigationTab == 'Main') ? `active` :null} onClick={() => setInventoryNavigationTab('Main')}>Main</InventoryNavigationTab>
                        <InventoryNavigationTab className={(inventoryNavigationTab == 'Etc') ? `active` :null} onClick={() => setInventoryNavigationTab('Etc')}>Etc</InventoryNavigationTab>
                    </InventoryNavigation>
                    <InventoryContent>
                        {
                            (inventoryNavigationTab == 'Equip') ?
                                <InventoryContentShop>
                                    <div className='row'>
                                        <div className='rowHead'>
                                            <div className='rowCircle'></div>

                                            <div className='rowTitle'>Weapon</div>
                                        </div>
                                        <div className='rowBody'>
                                            <div className='rowTask'>Small Sword</div>
                                        </div>
                                    </div>
                                </InventoryContentShop>
                            :null
                        }
                        {
                            (inventoryNavigationTab == 'Main') ?
                                <InventoryContentShop>
                                    <div className='row'>
                                        <div className='rowHead'>
                                            <div className='rowCircle'></div>

                                            <div className='rowTitle'>Potions</div>
                                        </div>
                                        <div className='rowBody'>
                                            <div className='rowTask'>Small Mana Potion</div>
                                        </div>
                                    </div>
                                </InventoryContentShop>
                            :null
                        }
                        {
                            (inventoryNavigationTab == 'Etc') ?
                                <InventoryContentShop>
                                    <div className='row'>
                                        <div className='rowHead'>
                                            <div className='rowCircle'></div>

                                            <div className='rowTitle'>Seeds</div>
                                        </div>
                                        <div className='rowBody'>
                                            <div className='rowTask'>Seedle Seeds</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='rowHead'>
                                            <div className='rowCircle'></div>

                                            <div className='rowTitle'>Pii</div>
                                        </div>
                                        <div className='rowBody'>
                                            <div className='rowTask'>Pii</div>
                                            <div className='rowTask'>Large Pii</div>
                                        </div>
                                    </div>
                                </InventoryContentShop>
                            :null
                        }
                    </InventoryContent>
                        {
                            state.results.map(row => (
                                <>

                                </>
                            ))
                        }


                </InventoryWrapper>
            </Wrapper>
        </>
    )
};

export default Inventory;