import React, { useEffect, useState } from 'react';
//API
import API from '../API';
//Mobile Check
import {isMobile} from 'react-device-detect';
//Functions
import UserFunctions from './UserFunctions';
// Components
import Header from './Header';
import Wrapper from './Wrapper'
import Spinner from './Spinner';
import PopUp from './PopUp';
import Input from './Input';
import Button from './Button';
//Styles
import { Wrapper as CharacterWrapper, CharacterNavigation, CharacterNavigationTab, CharacterContent, Char } from './Character/Character.styles';
//Hook
import { useCharacterFetch } from '../hooks/useCharacterFetch';
import GetText from './GetText';

const Character = () => {

    const {
        state,
        setReload: setReloadCharacterFetch
    } = useCharacterFetch(UserFunctions.getSessionHash());

    const [characterNavigationTab, setCharacterNavigationTab] = useState();
    const [characterRoles, setCharacterRoles] = useState(null);


    //Create a new Character
    const [showCreateCharacter, setShowCreateCharacter] = useState(false);
    const [characterName, setCharacterName] = useState('')
    const [characterRoleId, setCharacterRoleId] = useState(null)
    const [resultMessage, setResultMessage] = useState(null);
    const [errorCreateCharacter, setErrorCreateCharacter] = useState(false);
    const [loadingCreateCharacter, setLoadingCreateCharacter] = useState(false);

    const handleInput = e => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;

        if(name === 'characterName') setCharacterName(value)
        if(name === 'characterRoleId') setCharacterRoleId(value)
    }

    const createCharacter = async (action, characterId) => {
        try {
            setErrorCreateCharacter(false);
            setLoadingCreateCharacter(true);

            const apiRoom = await API.createCharacter(action, characterId, characterName, characterRoleId, UserFunctions.getSessionHash());

            var roles = apiRoom.results[0].characterRoles;

            if((roles != null) && (roles != characterRoles)) {
                setCharacterRoles(roles);
            }

            var characterId = apiRoom.results[0].characterId;
            var error = apiRoom.results[0].error;
            var deleted = apiRoom.results[0].deleted;
            var resultMessage = apiRoom.results[0].resultMessage;

            if(resultMessage != null) {
                setResultMessage(resultMessage);
            }

            if((deleted === true) && (error === false)) {
                //Success
                setReloadCharacterFetch(prev => !prev);
                setCharacterNavigationTab(null);
            }
            else if(characterId) {
                //Success
                setShowCreateCharacter(false);
                setReloadCharacterFetch(prev => !prev);
                setCharacterNavigationTab(characterId);
            }
            else if(error) {

            }
        } catch (error) {
            setErrorCreateCharacter(true);
        }
        setLoadingCreateCharacter(false);
        setCharacterRoleId(null);
    };
    //Create a new Character End

    useEffect(() => {
        if(characterRoles === null) {
            createCharacter('preload');
        }
    })

    return (
        <>
            <Header userName={UserFunctions.getUserName()} />
            <Wrapper>
                {
                (showCreateCharacter == true) ? 
                    <PopUp>
                        {resultMessage && <div>{<GetText textName={resultMessage} textStyle={'default'} />}</div>}
                        <Input type='text' placeholder='Character Name' name={'characterName'} onChange={handleInput}/>
                        <select onChange={(e) => setCharacterRoleId(parseInt(e.target.value))}>
                            <option value={null}>Select</option>
                            {
                                Array.isArray(characterRoles) && characterRoles.map(row => (
                                    <option value={row.characterRoleId}>{row.characterRoleName}</option>
                                ))
                            }
                        </select>
                        {
                            (loadingCreateCharacter) ?
                                <Spinner />
                            :
                                <Button text={'Create'} callback={() => createCharacter()}/>
                        }
                        
                        <Button text={'Close'} callback={() => setShowCreateCharacter(false)} />
                    </PopUp>
                :null
                }
                <CharacterWrapper>
                    <CharacterNavigation>
                        <CharacterNavigationTab
                            className={(characterNavigationTab === 'Create') ? `active` :null}
                            onClick={() =>
                                {
                                    setShowCreateCharacter(true);
                                }
                            }
                        >
                            Create
                        </CharacterNavigationTab>
                    </CharacterNavigation>
                    <CharacterNavigation>
                        {
                            Array.isArray(state.results) && state.results.map(row => (
                                <CharacterNavigationTab className={(characterNavigationTab === row.characterId) ? `active` :null} onClick={() => setCharacterNavigationTab(row.characterId)}>{row.characterName}</CharacterNavigationTab>
                            ))
                        }
                    </CharacterNavigation>
                    <CharacterContent>
                        {
                            Array.isArray(state.results) && state.results.map(row => (
                                (characterNavigationTab === row.characterId) ?
                                    <Char>
                                        <img src={`${process.env.PUBLIC_URL}/images/characterRole/3.png`}/>
                                        <p className='characterName'>Name: {row.characterName}</p>
                                        <p className='characterRoleName'>Type: {row.characterRoleName}</p>
                                        <p className='characterCreateDate'>Created: {row.characterCreateDate}</p>
                                        <p className='characterLevel'>Level: {row.characterLevel}</p>
                                        <p className='characterJobLevel'>JobLevel: {row.characterJobLevel}</p>
                                        <p className='characterAttack'>Attack: {row.characterAttack}</p>
                                        <p className='characterDefense'>Defense: {row.characterDefense}</p>
                                        <p className='characterHealth'>Health: {row.characterHealth}</p>
                                        <p className='characterMana'>Mana: {row.characterMana}</p>
                                        <div className='charLife'>
                                            <div className='green'></div>
                                            <div className='red'></div>
                                        </div>
                                        {
                                            (loadingCreateCharacter) ?
                                                <Spinner />
                                            :
                                                <Button className={'red'} text={<GetText textName={'delete'} />} callback={() => createCharacter('delete', row.characterId)}></Button>
                                        }
                                    </Char>
                                :null
                            ))

                        }


                    </CharacterContent>
                        {
                            state.results.map(row => (
                                <>

                                </>
                            ))
                        }


                </CharacterWrapper>
            </Wrapper>
        </>
    )
};

export default Character;