import React from 'react';
import { useParams } from 'react-router-dom';
//Functions
import UserFunctions from './UserFunctions';
import Wrapper from './Wrapper'
// Components
import UserInfo from './UserInfo';
import Header from './Header';
import { Wrapper as ProfileWrapper } from './Profile/profile.styles';

const Profile = () => {

    const {
        userName
    } = useParams();
    
    return (
        <>
            <Header userName={UserFunctions.getUserName()} />
            <Wrapper>
                <ProfileWrapper>
                    <UserInfo userName={userName} userHash={UserFunctions.getSessionHash()}/>
                </ProfileWrapper>
            </Wrapper>
        </>
    )
};

export default Profile;